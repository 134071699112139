import qs from 'qs';
import axios from '@/config/axios';

export default {
  create(data) {
    return axios.$post('/links', data);
  },
  getAll(params) {
    return axios.$get('/links', {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { encode: false, arrayFormat: 'brackets' });
      },
    });
  },
  getAllYTViews(params) {
    return axios.$get('/links/stats/youtube', {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { encode: false, arrayFormat: 'brackets' });
      },
    });
  },
  get(uuid) {
    return axios.$get(`/links/${uuid}`);
  },
  getYTViews(uuid) {
    return axios.$get(`/links/${uuid}/stats/youtube`);
  },
  update(uuid, data) {
    if (data instanceof FormData) {
      data.append('_method', 'PUT');
      return axios.$post(`/links/${uuid}`, data);
    }

    return axios.$put(`/links/${uuid}`, data);
  },
  delete(uuid) {
    return axios.$delete(`/links/${uuid}`);
  },
  duplicate(uuid, data) {
    return axios.$post(`/links/${uuid}/duplicate`, data);
  },
  getClicks(uuid, params = {}) {
    return axios.$get(`/links/${uuid}/clicks`, { params });
  },
  getClickStats(uuid, params = {}) {
    return axios.$get(`/links/${uuid}/stats/global`, { params });
  },
  createQRCode(uuid, data) {
    return axios.$post(`/links/${uuid}/qrcode`, data);
  },
};
