import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import router from './router';
import env from '../env';

Sentry.init({
  Vue,
  ...env.sentry,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', env.appURL, /^\//],
    }),
  ],
});
