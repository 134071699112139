import Vue from 'vue';
import get from 'lodash/get';
import { NotificationProgrammatic /* , ToastProgrammatic, DialogProgrammatic */ } from 'buefy';
import errorMessages from '@/messages/errors.json';
import router from '@/config/router';

const logoutRoute = { name: 'logout' };

const errorHandlers = {
  global(error) {
    console.log('axios', { ...error });

    if (!error.isAxiosError) {
      errorHandlers.default(error);
      return Promise.resolve();
    }

    const code = get(error, 'response.data.code', null);
    const status = get(error, 'response.status', null);

    if (code === 'PASSWORD_RESET_ERROR') {
      const message = get(error, 'response.data.message', errorMessages[500]);
      NotificationProgrammatic.open({
        type: 'is-danger',
        message: get(error, 'response.data.status', message),
        position: 'is-top',
      });
    } else if (status === 422) {
      errorHandlers.apiValidationErrors(error);
    } else if ([401, 419].includes(status)) {
      NotificationProgrammatic.open({
        type: 'is-danger',
        message: get(error, 'response.data.message', errorMessages[401]),
        position: 'is-top',
      });

      return router.push(logoutRoute);
    } else if ([307, 503].includes(status)) {
      NotificationProgrammatic.open({
        type: 'is-danger',
        message: get(error, 'response.data.message', errorMessages[307]),
        position: 'is-top',
      });
    } else if ([409, 500].includes(status)) {
      NotificationProgrammatic.open({
        type: 'is-danger',
        message: get(error, 'response.data.message', errorMessages[status]),
        position: 'is-top',
      });
    } else if ([429].includes(status)) {
      NotificationProgrammatic.open({
        type: 'is-danger',
        message: errorMessages[429],
        position: 'is-top',
      });
    } else if (![null, undefined].includes(status)) {
      NotificationProgrammatic.open({
        type: 'is-danger',
        message: get(error, 'response.data.message', errorMessages[500]),
        position: 'is-top',
      });
    } else {
      errorHandlers.stack(error);
    }

    return Promise.resolve();
  },
  apiValidationErrors(error) {
    const { errors } = error.response.data;
    errorHandlers.default({
      message: Object.keys(errors).map((field) => errors[field].join(', ')).join(', '),
    });
  },
  default(error) {
    if (error._isRouter || error.name === 'NavigationDuplicated') {
      return;
    }

    if (error.name === 'PrivacyError') {
      return;
    }

    NotificationProgrammatic.open({
      message: (error && error.message) || error,
      type: 'is-danger',
      position: 'is-top',
    });
  },
  stack(error) {
    console.log('stack');
    console.error(error);
  },
};

window.addEventListener('unhandledrejection', (event) => {
  event.preventDefault();
  const detail = event.detail || event;
  console.warn('unhandledrejection', { ...detail });
  const error = detail.reason || detail;
  errorHandlers.global(error);
});

Vue.config.errorHandler = function (error, vm, info) {
  console.log('Vue.errorHandler', error, vm, info);
  errorHandlers.stack(error);
};

Vue.prototype.$errorHandlers = errorHandlers;
