<script>
import Vue from 'vue';

export default {
  data: () => ({ routerReady: false }),
  created() {
    this.$router.onReady(() => (this.routerReady = true));
  },
  computed: {
    layout() {
      const name = this.$route.meta.layout || this.$layout.default;
      const { layouts } = this.$layout;

      if (Object.keys(layouts).length && layouts[name]) {
        return layouts[name];
      }

      if (!Vue.options.components[name]) {
        console.warn(`${name}Layout is not set as a global component or on "layouts" option.`);
      }

      return `${name}Layout`;
    },
  },
  watch: {
    layout(val) {
      this.$layout.current = val;
    },
  },
};
</script>

<template>
  <component v-if="routerReady" :is="layout">
    <slot />
  </component>
</template>
