<script>
import TrialNotice from '@/components/TrialNotice.vue';
import ClickLimitAlmostReachedNotice from '@/components/ClickLimitAlmostReachedNotice.vue';
import ClickLimitReachedNotice from '@/components/ClickLimitReachedNotice.vue';

export default {
  components: {
    TrialNotice,
    ClickLimitAlmostReachedNotice,
    ClickLimitReachedNotice,
  },

  computed: {
    capabilities() {
      return this.$store.getters['auth/capabilities'];
    },
  },
};
</script>

<template>
  <div>
    <ClickLimitReachedNotice
      v-if="$store.getters['auth/hasReachedClickLimit']"
      v-bind="$attrs"
      :class="$attrs.targetClass || 'is-danger'"
    />

    <ClickLimitAlmostReachedNotice
      v-else-if="$store.getters['auth/hasAlmostReachedClickLimit']"
      v-bind="$attrs"
      :class="$attrs.targetClass || 'is-danger'"
    />

    <TrialNotice
      v-else-if="$store.getters['auth/isOnTrial']"
      v-bind="$attrs"
      :class="$attrs.targetClass || 'is-warning'"
    />
  </div>
</template>
