<script>
import dompurify from 'dompurify';
import APIWP from '@/services/API/WP';

export default {
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    endpoint: {
      type: String,
      required: true,
    },
  },
  data: () => ({ title: '', content: '' }),
  methods: {
    onClick() {
      if (this.isLoading) {
        return;
      }

      if (this.title || this.content) {
        this.showAlert();
        return;
      }

      this.isLoading = this.$buefy.loading.open();
      APIWP.getEndpoint(this.endpoint)
        .then(({ title, content }) => {
          this.title = dompurify.sanitize(title.rendered);
          this.content = dompurify.sanitize(
            content.rendered || '(en construction)',
          );
          this.showAlert();
        })
        .finally(() => {
          this.isLoading.close();
          this.isLoading = false;
        });
    },
    showAlert() {
      this.$buefy.dialog.alert({
        title: this.title,
        message: this.content,
        confirmText: 'D\'accord',
      });
    },
  },
};
</script>

<template>
  <component :is="tag" @click.prevent="onClick">
    <slot />
  </component>
</template>
