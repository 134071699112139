<script>
import LinkList from '@/components/LinkList.vue';

export default {
  components: { LinkList },
};
</script>

<template>
  <div class="py-10">
    <h1 class="title">
      Tableau de bord
    </h1>

    <LinkList />
  </div>
</template>
