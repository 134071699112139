import APICollection from '@/services/API/Collection';

export const state = () => ({
  data: {
    data: null,
  },
});

export const mutations = {
  set(localState, payload) {
    localState.data = payload;
  },
  add(localState, payload) {
    const data = localState.data.data || [];
    localState.data.data = [...data, payload];
  },
  update(localState, payload) {
    const data = localState.data.data || [];
    localState.data.data = data.map((v) => (v.uuid === payload.uuid ? payload : v));
  },
  remove(localState, payload) {
    const data = localState.data.data || [];
    localState.data.data = data.filter((v) => v.uuid !== payload.uuid);
  },
};

export const actions = {
  create({ commit }, payload) {
    return APICollection.create(payload)
      .then((data) => commit('add', data));
  },
  load({ commit }, payload) {
    return APICollection.getAll(payload)
      .then((data) => commit('set', data));
  },
  update({ commit }, { uuid, data }) {
    return APICollection.update(uuid, data)
      .then((updatedData) => commit('update', updatedData));
  },
  delete({ commit }, payload) {
    return APICollection.delete(payload.uuid)
      .then(() => commit('remove', payload));
  },
};

export const getters = {
};
