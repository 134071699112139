import { wp } from '@/config/axios';

export default {
  getEndpoint(path) {
    return wp.$get(path);
  },
  getPage(id) {
    return wp.$get(`/pages/${id}`);
  },
};
