<script>
import env from '@/env';
import { randomAlphaNum } from '@/services/Utils';
import { apiForm, addOrUpdateForm } from '@/mixins/form';

export default {
  mixins: [
    apiForm({ formData: 'linkData', formProcess: 'save' }),
    addOrUpdateForm({ formData: 'linkData', formEntity: 'link' }),
  ],
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isFetching: false,
    linkData: {
      domain: env.shortDomain,
      slug: null,
    },
  }),
  computed: {
    domains() {
      return this.$store.state.domain.data;
    },
  },
  methods: {
    generateSlug() {
      this.linkData.slug = randomAlphaNum(8, false);
    },
    save(data) {
      const { uuid } = this.link;
      return this.$store.dispatch('link/duplicate', { uuid, data })
        .then((link) => {
          this.$emit('done', link);
          this.$buefy.toast.open({
            type: 'is-success',
            message: 'Le lien a été dupliqué.',
          });
        });
    },
  },
  created() {
    this.isFetching = true;
    this.$store.dispatch('domain/load')
      .finally(() => (this.isFetching = false));

    this.generateSlug();
  },
};
</script>

<template>
  <BaseModalCard tag="form" @submit.prevent="formSubmit">
    <template #title>
      Dupliquer le lien
    </template>
    <template #body>
      <div class="columns is-multiline mb-0">
        <div class="column is-6">
          <b-field label="Domaine" label-for="domain">
            <b-select
              v-model="linkData.domain"
              id="domain"
              expanded>
              <option :value="$env.shortDomain">
                {{ $env.shortDomain }}
              </option>
              <template v-if="domains && domains.length">
                <option
                  v-for="domain in domains"
                  :key="domain.uuid"
                  :class="{'has-background-grey-lighter': !domain.is_enabled}"
                  :value="domain.name"
                  :disabled="!domain.is_enabled">
                  {{ domain.name }}
                  <span v-if="!domain.is_enabled">
                    (désactivé)
                  </span>
                </option>
              </template>
            </b-select>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="URL" label-for="slug">
            <b-input
              v-model.trim="linkData.slug"
              id="slug"
              maxlength="100"
              placeholder="VotreUrl"
              pattern="^[a-zA-Z0-9-_]+$"
              :has-counter="false"
              expanded
              required
            />
            <p class="control">
              <b-tooltip label="Générer" position="is-top">
                <b-button
                  type="is-primary-gradient"
                  icon-left="sync"
                  @click="generateSlug"
                />
              </b-tooltip>
            </p>
          </b-field>
        </div>
        <div class="column is-12 pt-0 is-size-7">
          <strong>Aperçu du lien</strong> :
          <a class="has-text-black is-underlined" href="#" @click.prevent>
            https://{{ linkData.domain }}/{{ linkData.slug }}
          </a>
        </div>
      </div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Annuler
      </b-button>
      <b-button
        type="is-primary-gradient"
        native-type="submit"
        :loading="formIsLoading"
      >
        Dupliquer
      </b-button>
    </template>
  </BaseModalCard>
</template>
