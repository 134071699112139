<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'horizontal',
      validator(value) {
        return ['vertical', 'horizontal'].includes(value);
      },
    },
  },
};
</script>

<template>
  <div
    :class="[$attrs.class, {
      'box px-5 pb-8 pt-0': type == 'vertical',
      'box py-2 flex-between-centered': type == 'horizontal'
    }]"
  >
    <div :class="{'flex-center-center': type == 'horizontal'}">
      <figure
        :class="[$style.offsetImage, {
          [$style.isVertical]: type == 'vertical',
          [$style.isHorizontal]: type == 'horizontal'
        }]"
      >
        <slot name="image" />
      </figure>

      <div :class="{'ml-10': type == 'horizontal'}">
        <slot />
      </div>
    </div>

    <p :class="{'mt-3': type == 'vertical'}">
      <slot name="action" />
    </p>
  </div>
</template>

<style lang="scss" module>
.offsetImage {
  &.isVertical {
    text-align: center;

    img {
      height: 140px;
      margin-top: -70px;
    }
  }

  &.isHorizontal {
    img {
      height: 96px;
    }
  }
}
</style>
