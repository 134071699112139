<script>
export default {
  metaInfo() {
    return {
      title: 'LienVisuel',
    };
  },

  data: () => ({ isReady: false }),

  created() {
    this.$store.dispatch('auth/ready')
      .finally(() => (this.isReady = true));
  },
};
</script>

<template>
  <div id="app">
    <template v-if="isReady">
      <MFLayout>
        <router-view v-if="$route.matched.length > 1" />
        <router-view v-else :key="$route.fullPath" />
      </MFLayout>
    </template>
    <b-loading v-else active />
  </div>
</template>
