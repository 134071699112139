import Vue from 'vue';
import MFLayout from '@/plugins/mfLayout';
import App from '@/layouts/AppLayout.vue';
import Blank from '@/layouts/BlankLayout.vue';

Vue.use(MFLayout, {
  defaultLayout: 'App',
  layouts: {
    App,
    Blank,
  },
});
