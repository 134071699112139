import APIAuth from '@/services/API/Auth';

export default {
  routes: {
    afterRegister: '/',
    afterLogin: '/',
    afterLogout: '/login',
  },
  setRoutes(routes) {
    this.routes = { ...this.routes, ...routes };
  },
  hasBeenPersisted() {
    return window.localStorage.getItem('__app_iuli') === '1';
  },
  csrf() {
    return APIAuth.csrf();
  },
  login(data) {
    return APIAuth.csrf()
      .then(() => APIAuth.login(data))
      .then((response) => {
        window.localStorage.setItem('__app_iuli', '1');
        return response;
      });
  },
  logout() {
    return APIAuth.logout()
      .then((response) => {
        window.localStorage.removeItem('__app_iuli');
        return response;
      });
  },
};
