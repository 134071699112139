<script>
import { mapGetters } from 'vuex';
import BaseNotice from './BaseNotice.vue';
import AllowedClicksProgress from './AllowedClicksProgress.vue';

export default {
  components: { BaseNotice, AllowedClicksProgress },

  props: {
    type: {
      type: String,
      default: () => 'horizontal',
      validator(value) {
        return ['vertical', 'horizontal'].includes(value);
      },
    },
  },

  computed: {
    ...mapGetters({ currentMonthLimitClickErrorCount: 'auth/currentMonthLimitClickErrorCount' }),
  },
};
</script>

<template>
  <BaseNotice v-bind="{ ...$attrs, type }">
    <template #image>
      <img
        src="@/assets/images/3d_dashboard.png"
        alt="image"
      />
    </template>

    <h3 class="is-size-4 has-text-weight-bolder mb-2 mt-3">
      Vous avez utilisé tous vos clics !
    </h3>

    <p class="is-size-7">
      Vous avez utilisé <b>100%</b> de vos clics ce mois-ci, pensez à changer de plan pour débloquer vos liens.
    </p>

    <p v-if="currentMonthLimitClickErrorCount" class="is-size-7">
      Vous avez déjà perdu
      <strong class="has-text-danger">
        {{ currentMonthLimitClickErrorCount }}
      </strong>
      redirection<template v-if="currentMonthLimitClickErrorCount > 1">s</template>
      de lien depuis que vous avez atteint la limite de votre plan.
    </p>

    <div v-if="type === 'horizontal'" class="mt-5 mb-3">
      <AllowedClicksProgress />
    </div>

    <template #action>
      <a
        class="button is-warning-gradient w-full"
        :href="$env.billingURL"
        @click="$logspot.track({
          event: 'GoToSubscription',
          metadata: { where: 'ClickLimitReachedNotice' },
        })"
      >
        Voir les offres
      </a>
    </template>
  </BaseNotice>
</template>
