import APILink from '@/services/API/Link';

export const state = () => ({
  data: null,
});

export const mutations = {
  set(localState, payload) {
    localState.data = payload;
  },
  add(localState, payload) {
    const data = localState.data || [];
    localState.data = [...data, payload];
  },
  update(localState, payload) {
    const data = localState.data || [];
    localState.data = data.map((v) => (v.uuid === payload.uuid ? payload : v));
  },
  remove(localState, payload) {
    const data = localState.data || [];
    localState.data = data.filter((v) => v.uuid !== payload.uuid);
  },
};

export const actions = {
  create({ commit }, payload) {
    return APILink.create(payload)
      .then((data) => {
        commit('add', data);
        return data;
      });
  },
  load({ commit }, payload) {
    return APILink.getAll(payload)
      .then((data) => {
        commit('set', data);
        return data;
      });
  },
  update({ commit }, { uuid, data }) {
    return APILink.update(uuid, data)
      .then((newData) => {
        commit('update', newData);
        return newData;
      });
  },
  delete({ commit }, payload) {
    return APILink.delete(payload.uuid)
      .then(() => commit('remove', payload));
  },
  duplicate({ commit }, { uuid, data }) {
    return APILink.duplicate(uuid, data)
      .then((newData) => {
        commit('add', newData);
        return newData;
      });
  },
};

export const getters = {
};
