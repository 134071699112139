import cloneDeep from 'lodash/cloneDeep';

export default null;

export const addOrUpdateForm = (options = {}) => {
  const defaults = {
    formData: 'formData',
    formEntity: 'formEntity',
    ...options,
  };

  return {
    created() {
      const entity = cloneDeep(this[defaults.formEntity]);
      const data = this[defaults.formData];

      if (entity) {
        Object.keys(data)
          .forEach((k) => (data[k] = entity[k]));
      }
    },
  };
};

export const apiForm = (options = {}) => {
  const defaults = {
    formData: 'formData',
    formSubmit: 'formSubmit',
    formProcess: 'formProcess',
    formIsLoading: 'formIsLoading',
    ...options,
  };

  return {
    data() {
      return {
        [defaults.formIsLoading]: false,
      };
    },
    methods: {
      [defaults.formSubmit]() {
        if (this[defaults.formIsLoading]) {
          return;
        }

        this[defaults.formIsLoading] = true;

        const process = this[defaults.formProcess]({ ...this[defaults.formData] });

        if (process && typeof process.finally === 'function') {
          process.finally(() => (this[defaults.formIsLoading] = false));
        } else {
          this[defaults.formIsLoading] = false;
        }
      },
      // Default process function
      [defaults.formProcess](data) {
        return Promise.resolve(data);
      },
    },
  };
};
