import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/HomePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { auth: true },
    component: Home,
  },
  {
    path: '/connexion',
    name: 'login',
    meta: { layout: 'Blank', auth: false },
    component: () => import('@/pages/LoginPage.vue'),
  },
  {
    path: '/deconnexion',
    name: 'logout',
    meta: {
      layout: 'Blank',
      auth: true,
      verified: false,
      lockedCanSee: true,
    },
    component: () => import('@/pages/LogoutPage.vue'),
  },
  {
    path: '/inscription',
    name: 'register',
    meta: { layout: 'Blank', auth: false },
    component: () => import('@/pages/RegisterPage.vue'),
  },
  {
    path: '/viededingue',
    name: 'register-vdd',
    meta: { layout: 'Blank', auth: false },
    component: () => import('@/pages/VddRegisterPage.vue'),
  },
  {
    path: '/reinitialiser-mdp/:token',
    name: 'reset-password',
    meta: { layout: 'Blank', auth: false },
    component: () => import('@/pages/ResetPasswordPage.vue'),
  },
  {
    path: '/confirmez-votre-email',
    name: 'verify-email-notice',
    meta: {
      layout: 'Blank',
      auth: true,
      verified: false,
      lockedCanSee: true,
    },
    component: () => import('@/pages/EmailVerificationNoticePage.vue'),
  },
  {
    path: '/email/verif/:id/:hash',
    name: 'verify-email',
    meta: {
      auth: true,
      verified: false,
      lockedCanSee: true,
    },
    component: () => import('@/pages/EmailVerificationPage.vue'),
  },
  {
    path: '/compte-bloque',
    name: 'account-locked',
    meta: { layout: 'Blank', auth: true },
    component: () => import('@/pages/LockedAccountPage.vue'),
  },
  {
    path: '/domaines',
    name: 'domains',
    meta: { auth: true },
    component: () => import('@/pages/DomainsPage.vue'),
  },
  {
    path: '/etiquettes',
    name: 'tags',
    meta: { auth: true },
    component: () => import('@/pages/TagsPage.vue'),
  },
  {
    path: '/collections',
    name: 'collections',
    meta: { auth: true },
    component: () => import('@/pages/CollectionsPage.vue'),
  },
  {
    path: '/collection/:uuid',
    name: 'collection-item',
    meta: { auth: true },
    component: () => import('@/pages/CollectionPage.vue'),
  },
  {
    path: '/liens/creer',
    name: 'links-create',
    meta: { auth: true },
    component: () => import('@/pages/CreateLinkPage.vue'),
  },
  {
    path: '/liens/:uuid',
    name: 'links-edit',
    meta: { auth: true },
    component: () => import('@/pages/EditLinkPage.vue'),
  },
  {
    path: '/liens/:uuid/stats',
    meta: { auth: true },
    component: () => import('@/pages/LinkStatsPage.vue'),
    children: [
      {
        path: '',
        name: 'link-clicks-stats',
        meta: { auth: true },
        component: () => import('@/pages/LinkStats/LinkClickStatsPage.vue'),
      },
      {
        path: 'clics',
        name: 'link-clicks-list',
        meta: { auth: true },
        component: () => import('@/pages/LinkStats/LinkClicksPage.vue'),
      },
    ],
  },
  {
    path: '/pages',
    name: 'pages',
    meta: { auth: true },
    component: () => import('@/pages/PageListPage.vue'),
  },
  {
    path: '/page/:uuid',
    meta: { auth: true },
    component: () => import('@/pages/PagePage.vue'),
    children: [
      {
        path: '',
        name: 'page-blocks',
        component: () => import('@/pages/Page/BlocksPage.vue'),
      },
      {
        path: 'design',
        name: 'page-design',
        component: () => import('@/pages/Page/DesignPage.vue'),
      },
      {
        path: 'stats',
        name: 'page-stats',
        component: () => import('@/pages/Page/StatsPage.vue'),
      },
      {
        path: 'parametres',
        name: 'page-settings',
        component: () => import('@/pages/Page/SettingsPage.vue'),
      },
    ],
  },
  {
    path: '/parametres',
    name: 'settings',
    meta: { auth: true },
    component: () => import('@/pages/SettingsPage.vue'),
  },
  {
    path: '/abonnement',
    name: 'billing',
    meta: { auth: true },
    component: () => import('@/pages/BillingPage.vue'),
  },
  {
    path: '/equipe',
    name: 'team',
    meta: { auth: true },
    component: () => import('@/pages/TeamPage.vue'),
  },
  {
    path: '/affilitation',
    meta: { auth: true },
    component: () => import('@/pages/GodfatherPage.vue'),
    children: [
      {
        path: '',
        name: 'godfather',
        meta: { auth: true },
        component: () => import('@/pages/Godfather/IndexPage.vue'),
      },
      {
        path: 'inscrits',
        name: 'godfather_children',
        meta: { auth: true },
        component: () => import('@/pages/Godfather/ChildrenPage.vue'),
      },
    ],
  },
  {
    path: '*',
    meta: { layout: 'Blank', auth: true },
    component: () => import('@/pages/Error404Page.vue'),
  },
  // exemple de page privée
  // {
  //   path: '/besoin/detre/verifie',
  //   name: 'needs-verify',
  //   meta: { auth: true, verified: true },
  //   component: Home,
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        // , offset: { x: 0, y: 10 }
      };
    }

    return { x: 0, y: 0 };
  },
});

Vue.prototype.$routeAppend = function (route) {
  const newRoute = {
    ...route,
    query: { ...this.$route.query, ...route.query },
  };

  this.$router.push(newRoute);
};

export default router;
