import env from '@/env';
import axios from 'axios';
import Vue from 'vue';

/* eslint-disable no-multi-assign */

axios.$get = axios.Axios.prototype.$get = function (...args) {
  return this.get(...args).then((res) => res.data);
};
axios.$post = axios.Axios.prototype.$post = function (...args) {
  return this.post(...args).then((res) => res.data);
};
axios.$put = axios.Axios.prototype.$put = function (...args) {
  return this.put(...args).then((res) => res.data);
};
axios.$patch = axios.Axios.prototype.$patch = function (...args) {
  return this.patch(...args).then((res) => res.data);
};
axios.$delete = axios.Axios.prototype.$delete = function (...args) {
  return this.delete(...args).then((res) => res.data);
};

axios.defaults.baseURL = env.apiURL;
axios.defaults.withCredentials = true;

export default axios;
export const wp = axios.create({
  baseURL: env.wpAPIURL,
  withCredentials: false,
});

Vue.prototype.$axios = axios;
