import './assets/styles/index.scss';
import Vue from 'vue';
import env from './env';
import constants from './constants';
import './config/logspot';
import './config/buefy';
import './config/axios';
import './config/daysjs';
import './config/messages';
import './config/clipboard';
import './config/socialSharing';
import './config/advancedCropper';
import './config/vueMeta';
import './config/vuePlyr';
import router from './config/router';
import store from './config/store';
import './config/auth';
import './config/errors';
import './config/mfLayout';
import './config/sentry';

// We load affiliation middleware first to avoid redirection which will loose the godfather code from the URL
import './middlewares/logspot';
import './middlewares/affiliation';
import './middlewares/auth';

import './components/globalComponents';
import App from './App.vue';

Vue.prototype.$env = env;
Vue.prototype.$constants = constants;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
