<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      nbUsedClicks: 'auth/nbUsedClicks',
      nbAllowedClicks: 'auth/nbAllowedClicks',
      clickUsagePercent: 'auth/clickUsagePercent',
    }),
    clickUsageColor() {
      return {
        'is-black': this.clickUsagePercent <= 0.7,
        'is-warning': this.clickUsagePercent > 0.7 && this.clickUsagePercent < 0.9,
        'is-danger': this.clickUsagePercent >= 0.9,
      };
    },
  },
};
</script>

<template>
  <div>
    <p
      class="flex-between-centered has-text-weight-semibold"
      :class="{
        'mb-2': nbAllowedClicks != -1
      }"
    >
      Votre usage
      <span>
        ({{ nbUsedClicks }} clic{{ nbUsedClicks > 1 ? 's' : '' }} / {{ nbAllowedClicks == -1 ? 'illimités' : nbAllowedClicks }})
      </span>
    </p>
    <b-progress
      v-if="nbAllowedClicks != -1"
      :type="clickUsageColor"
      :value="nbUsedClicks"
      :max="nbAllowedClicks"
    />
  </div>
</template>
