<script>
import APILink from '@/services/API/Link';
import LinkCard from '@/components/LinkCard.vue';

export default {
  components: { LinkCard },
  props: {
    collectionUuid: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      filter: {
        search: null,
        collection: null,
        domain: this.$route.query.domain,
        tag: this.$route.query.tag,
      },
      sort: {
        name: 'created_at',
        order: 'desc',
      },
      links: {
        data: [],
      },
    };
  },
  computed: {
    hasClosableFilters() {
      return !!(
        this.filter.domain
        || this.filter.tag
      );
    },
    domainFilterName() {
      return this.$route.query.domain;
    },
    tagFilterName() {
      return this.$route.query.tagName;
    },
  },
  methods: {
    loadLinks() {
      const loader = this.$buefy.loading.open();
      const { page, filter, sort } = this;

      filter.collection = this.collectionUuid;

      APILink.getAll({ page, filter, sort })
        .then((links) => {
          this.links = links;

          const uuids = links.data
            .filter((v) => !!v.youtube_id)
            .map((v) => v.uuid);

          if (uuids.length > 0) {
            return APILink.getAllYTViews({
              uuids,
            })
              .then((stats) => (this.links.data = this.links.data.map((v) => {
                if (stats[v.uuid]) {
                  return { ...v, youtube_stats: stats[v.uuid] };
                }

                return v;
              })));
          }

          return null;
        })
        .finally(() => loader.close());
    },
    onPageChange(page) {
      this.$routeAppend({ query: { page } });
    },
    onSortClick() {
      if (this.sort.order === 'asc') {
        this.sort.order = 'desc';
      } else {
        this.sort.order = 'asc';
      }

      this.loadLinks();
    },
    onCreateQRCode(link) {
      const loader = this.$buefy.loading.open();
      APILink.createQRCode(link.uuid)
        .then((qrcode) => {
          this.links.data = this.links.data.map((v) => (
            v.uuid === link.uuid ? ({ ...link, qr_code: qrcode }) : v
          ));

          this.$buefy.dialog.alert({
            type: 'is-primary-gradient',
            message: `
              Le QRCode a été créé, re-cliquez sur l'icône pour le voir
              et le télécharger.
            `,
            confirmText: 'Ok !',
          });
        })
        .finally(() => loader.close());
    },
    onDeleteLink(link) {
      const loader = this.$buefy.loading.open();
      APILink.delete(link.uuid)
        .then(() => {
          this.links.data = this.links.data.filter(
            (v) => v.uuid !== link.uuid,
          );
          this.links.total -= 1;
        })
        .finally(() => loader.close());
    },
    onUpdateLink(link) {
      this.links.data = this.links.data.map((v) => (
        v.uuid === link.uuid ? ({ ...v, ...link }) : v
      ));
    },
    onDuplicateLink(link) {
      this.links.data.unshift(link);
      this.links.total += 1;
    },
  },
  created() {
    this.loadLinks();
  },
};
</script>

<template>
  <section>
    <header class="flex-between-centered is-flex-wrap-wrap mb-6">
      <h2 class="title is-5 mb-0">
        Total des liens ({{ links.total || 0 }})
      </h2>
      <nav class="is-flex is-flex-wrap-wrap is-hidden-mobile">
        <form class="mr-3 is-flex" @submit.prevent="loadLinks">
          <b-field class="mb-0" label-for="search">
            <b-input
              v-model="filter.search"
              class="w-full w-280"
              type="search"
              id="search"
              placeholder="Filtrer la liste par libéllé, URL"
            />
            <p class="control">
              <b-button
                class="is-primary-gradient"
                native-type="submit"
                icon-left="search"
              />
            </p>
          </b-field>
          <b-field
            class="ml-3 mb-0"
            label="Trier"
            label-for="sort"
            label-position="on-border"
          >
            <b-select v-model="sort.name" id="sort" @input="loadLinks">
              <option value="created_at">Date</option>
              <option value="label">Libellé</option>
              <option value="click_count">Clics</option>
              <option value="domain">Nom de domaine</option>
              <option value="slug">URL</option>
            </b-select>
            <p class="control">
              <b-tooltip>
                <template #content>
                  <template v-if="sort.order == 'asc'">
                    Ascendant
                  </template>
                  <template v-else>
                    Descendant
                  </template>
                </template>
                <b-button
                  class="is-primary-gradient"
                  :icon-left="sort.order == 'asc' ? 'arrow-up' : 'arrow-down'"
                  @click="onSortClick"
                />
              </b-tooltip>
            </p>
          </b-field>
        </form>

        <BaseIconButton
          tag="router-link"
          class="is-primary-gradient"
          :to="{name: 'links-create', query: {collection: collectionUuid}}"
          @click="$logspot.track({
            event: 'AddLink',
            metadata: {
              where: 'LinkListNav',
              collection: collectionUuid,
            },
          })"
        >
          <template #icon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.167 15.167h-7v7h-2.334v-7h-7v-2.334h7v-7h2.334v7h7v2.334z" fill="#fff"/></svg>
          </template>
          Créer un lien
        </BaseIconButton>
      </nav>
    </header>
    <div v-if="hasClosableFilters" class="field is-grouped is-grouped-multiline">
      <p class="mr-2">
        Filtres :
      </p>
      <div v-if="domainFilterName" class="control">
        <b-tag
          type="is-dark"
          attached
          closable
          closeType="is-danger"
          @close="$routeAppend({query: {page: 1, domain: undefined}})">
          {{ domainFilterName }}
        </b-tag>
      </div>
      <div v-if="tagFilterName" class="control">
        <b-tag
          type="is-dark"
          attached
          closable
          closeType="is-danger"
          @close="$routeAppend({query: {page: 1, tag: undefined}})">
          {{ tagFilterName }}
        </b-tag>
      </div>
    </div>
    <template v-if="links.data.length">
      <LinkCard
        v-for="link in links.data"
        :key="link.uuid"
        :link="link"
        @update:link="onUpdateLink"
        @delete="onDeleteLink"
        @duplicate="onDuplicateLink"
        @create-qrcode="onCreateQRCode"
      />
      <b-pagination
        v-if="links.last_page > 1"
        :total="links.total"
        v-model="page"
        :per-page="links.per_page"
        icon-prev="angle-left"
        icon-next="angle-right"
        @change="onPageChange"
      />
    </template>
    <div v-else class="box content has-text-centered">
      <p class="has-text-weight-bolder is-size-5">
        Il n'y a pas de liens.
      </p>
      <p>
        <BaseIconButton
          tag="router-link"
          class="is-primary-gradient"
          :to="{name: 'links-create', query: {collection: collectionUuid}}"
          @click="$logspot.track({
            event: 'AddLink',
            metadata: {
              where: 'LinkListEmpty',
              collection: collectionUuid,
            },
          })"
        >
          <template #icon>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.167 15.167h-7v7h-2.334v-7h-7v-2.334h7v-7h2.334v7h7v2.334z" fill="#fff"/></svg>
          </template>
          Créer un lien
        </BaseIconButton>
      </p>
    </div>
  </section>
</template>
