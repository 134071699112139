<script>
import utilsMixin from '@/mixins/utils';
import { downloadFile, pluralText as _pt } from '@/services/Utils';
import DuplicateLinkForm from '@/components/DuplicateLinkForm.vue';
import LinkUpdateCollectionForm from '@/components/LinkUpdateCollectionForm.vue';
import SuperTrackingForm from '@/components/SuperTrackingForm.vue';

export default {
  mixins: [utilsMixin],
  components: { DuplicateLinkForm, LinkUpdateCollectionForm },
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    hasQRCodeOpen: false,
    hasDuplicationOpen: false,
    hasUpdateCollectionOpen: false,
    hasPreviewOpen: false,
  }),
  computed: {
    shortUrl() {
      return `${window.location.protocol}//${this.link.domain}/${this.link.slug}`;
    },
    ytViews() {
      return (
        this.link.youtube_stats?.view_count
        || this.link.initial_youtube_view_count
      );
    },
    ytViewsSinceAdd() {
      return (
        this.ytViews - this.link.initial_youtube_view_count
      );
    },
    image() {
      if (!this.link.image) {
        return null;
      }

      if (this.link.image.path.match(/^https?:\/\//)) {
        return this.link.image.path;
      }

      return `${this.$env.assetURL}${this.link.image.path}`;
    },
    qrCode() {
      if (!this.link.qr_code) {
        return null;
      }

      return `${this.$env.assetURL}${this.link.qr_code.path}`;
    },
    targetDomain() {
      const targetUrl = new window.URL(this.link.target_url);
      return targetUrl.hostname;
    },
    hasExpireAt() {
      return !!this.link.expired_at;
    },
    hasExpired() {
      const date = this.link.expired_at;
      return this.$dayjs().isAfter(this.$dayjs.utc(date).local());
    },
    isOnStatsPage() {
      return [
        'link-clicks-stats',
        'link-clicks-list',
      ].includes(this.$route.name);
    },
  },
  methods: {
    downloadFile,
    onCopySuccess() {
      this.$messages.success('Le lien a été copié');
    },
    onCopyError() {
      this.$messages.error('Une erreur est survenue lors de la copie du lien');
    },
    openSuperTrackingForm() {
      this.$buefy.modal.open({
        parent: this,
        component: SuperTrackingForm,
        hasModalCard: true,
        props: {
          link: this.link,
        },
      });
    },
    onUpdateLink(link) {
      this.$emit('update:link', link);
      this.hasUpdateCollectionOpen = false;
    },
    onDuplicateLink(link) {
      this.$emit('duplicate', link);
      this.hasDuplicationOpen = false;
    },
    onSelectQRCode() {
      if (this.qrCode) {
        this.hasQRCodeOpen = true;
        return;
      }

      this.$emit('create-qrcode', this.link);
    },
    changeCollection() {
      this.hasUpdateCollectionOpen = true;
    },
    duplicateLink() {
      this.hasDuplicationOpen = true;
    },
    deleteLink() {
      const count = this.link.click_count;

      if (count) {
        this.$buefy.dialog.confirm({
          type: 'is-danger',
          title: 'Attention aux impactes',
          message: `
            Ce lien a déjà enregistré ${count} ${_pt('clic', count)},
            en le supprimant, tous ceux qui iront sur ce lien verrons
            une page d'erreur.
          `,
          focusOn: 'cancel',
          onConfirm: () => this.continueDelete(),
        });

        return;
      }

      this.continueDelete();
    },
    continueDelete() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: 'Action définitive',
        message: `
          Vous ne pourrez plus récupérer ce lien après sa suppression.
          En le supprimant, vous perdrez aussi TOUT l'historique des clics.
        `,
        confirmText: 'Confirmer la suppression',
        focusOn: 'cancel',
        onConfirm: () => this.$emit('delete', this.link),
      });
    },
  },
};
</script>

<template>
  <article class="box" :class="$style.card">
    <div class="flex-between-centered" :class="$style.content">
      <figure
        class="mr-5 has-background-grey bradius-normal is-clipped is-hidden-mobile"
        :class="$style.image"
      >
        <router-link
          class="is-block w-full h-full"
          :to="{name: 'link-clicks-stats', params: {uuid: link.uuid}}"
        >
          <img
            v-if="image"
            class="ofit-cover w-full h-full"
            :src="image"
            :alt="link.title"
          >
        </router-link>
      </figure>
      <div :class="$style.texts">
        <h3
          class="is-size-4 has-text-weight-bolder mb-2"
          :class="$style.texts"
        >
          <router-link
            class="color-inherit"
            :to="{name: 'link-clicks-stats', params: {uuid: link.uuid}}"
          >
            {{ link.label }}
          </router-link>
        </h3>
        <p
          class="has-text-clipped mb-2"
          :class="$style.texts"
        >
          Lien :
          <a
            :href="`https://${link.domain}/${link.slug}`"
            v-clipboard:copy="shortUrl"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            @click.prevent
          >
            {{ link.domain }}/{{ link.slug }}

            <b-tooltip append-to-body>
              <template #content>
                Cliquer pour copier le lien
              </template>
              <b-icon type="is-grey" pack="far" icon="clipboard" />
            </b-tooltip>
          </a>
        </p>
        <p
          class="has-text-clipped"
          :class="$style.texts"
        >
          Cible :
          <a :href="link.target_url" target="_blank" ref="noopener noreferrer">
            {{ link.target_url }}
          </a>
        </p>
      </div>
    </div>
    <div :class="$style.clicks">
      <template v-if="isOnStatsPage">
        <div class="is-inline-block py-4 w-100 has-text-centered">
          <span class="is-size-6 has-text-weight-semibold">
            Clics
          </span>
          <br />
          <b-tooltip :active="link.qrcode_count > 0">
            <template #content>
              Dont {{ link.qrcode_count }} via le QR Code
            </template>
            <span class="is-size-4 has-text-weight-semibold">
              {{ link.click_count | toShortNb }}
            </span>
          </b-tooltip>
        </div>
        <div
          v-if="ytViews != null"
          class="is-inline-block py-4 w-100 has-text-centered"
        >
          <span class="is-size-6 has-text-danger has-text-weight-semibold">
            Vues
            <b-icon style="margin-left: 1px" pack="fab" icon="youtube" />
          </span>
          <br />
          <span class="is-size-4 has-text-weight-semibold">
            <b-tooltip multilined>
              <template #content>
                <p>
                  Vues total : {{ ytViews | toShortNb }}
                  <br>
                  Conversion total :
                  {{ [link.click_count, ytViews, 3] | toPercent }}
                </p>
                <p class="mt-3">
                  Vues depuis l'ajout : {{ ytViewsSinceAdd | toShortNb }}
                  <br>
                  Conversion depuis l'ajout :
                  {{ [link.click_count, ytViewsSinceAdd, 3] | toPercent }}
                </p>
              </template>
              {{ ytViews | toShortNb }}
            </b-tooltip>
          </span>
        </div>
      </template>
      <template v-else>
        <b-button
          class="px-8"
          tag="router-link"
          :to="{name: 'link-clicks-stats', params: {uuid: link.uuid}}"
          type="is-text"
        >
          <span class="is-size-6 has-text-weight-semibold">
            Clics
          </span>
          <br>
          <b-tooltip :active="link.qrcode_count > 0">
            <template #content>
              Dont {{ link.qrcode_count }} via le QR Code
            </template>
            <span class="is-size-4 has-text-weight-semibold">
              {{ link.click_count | toShortNb }}
            </span>
          </b-tooltip>
        </b-button>
        <b-button
          v-if="ytViews != null"
          class="px-8"
          tag="router-link"
          :to="{name: 'link-clicks-stats', params: {uuid: link.uuid}}"
          type="is-text"
        >
          <span class="is-size-6 has-text-danger has-text-weight-semibold">
            Vues
            <b-icon style="margin-left: 1px" pack="fab" icon="youtube" />
          </span>
          <br>
          <span class="is-size-4 has-text-weight-semibold">
            <b-tooltip multilined>
              <template #content>
                <p>
                  Vues total : {{ ytViews | toShortNb }}
                  <br>
                  Conversion :
                  {{ [link.click_count, ytViews, 3] | toPercent }}
                </p>
                <p class="mt-3">
                  Vues depuis l'ajout : {{ ytViewsSinceAdd | toShortNb }}
                  <br>
                  Conversion :
                  {{ [link.click_count, ytViewsSinceAdd, 3] | toPercent }}
                </p>
              </template>
              {{ ytViews | toShortNb }}
            </b-tooltip>
          </span>
        </b-button>
      </template>
    </div>
    <div :class="$style.actions">
      <div class="tags" :class="$style.tags">
        <template v-if="hasExpireAt">
          <p v-if="hasExpired" class="tag is-black">
            <b-tooltip>
              <template #content>
                A expiré le {{ link.expired_at | fromUTC | formatDate('DD/MM/YY [à] HH:mm') }}
              </template>
              A expiré {{ link.expired_at | fromUTC | fromNow }}
            </b-tooltip>
          </p>
          <p v-else class="tag is-info">
            <b-tooltip>
              <template #content>
                Expire le {{ link.expired_at | fromUTC | formatDate('DD/MM/YY [à] HH:mm') }}
              </template>
              Expire {{ link.expired_at | fromUTC | fromNow }}
            </b-tooltip>
          </p>
        </template>
        <p class="tag">
          <b-tooltip>
            <template #content>
              Créé le {{ link.created_at | fromUTC | formatDate('DD/MM/YY [à] HH:mm') }}
            </template>
            Créé {{ link.created_at | fromUTC | fromNow }}
          </b-tooltip>
        </p>
        <p v-if="link.type === 'DYNAMIC'" class="tag is-warning">
          <b-tooltip position="is-left">
            <template #content>
              Ceci est un lien dynamique
            </template>
            <b-icon icon="magic" />
          </b-tooltip>
        </p>
      </div>

      <div :class="$style.buttons">
        <b-dropdown class="has-modal-dropdown" position="is-top-left">
          <template #trigger>
            <b-tooltip>
              <template #content>
                Partager
              </template>
              <BaseIconButton>
                <template #icon>
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M23 0c-2.749 0-5 2.25-5 5 0 .448.065.88.177 1.294L8.926 10.92A4.994 4.994 0 005 9c-2.75 0-5 2.25-5 5s2.25 5 5 5a4.997 4.997 0 003.925-1.92l9.252 4.627A4.972 4.972 0 0018 23c0 2.75 2.251 5 5 5 2.75 0 5-2.25 5-5s-2.25-5-5-5c-1.59 0-3.008.755-3.926 1.92l-9.251-4.626C9.935 14.88 10 14.448 10 14c0-.448-.065-.88-.177-1.293l9.252-4.626A4.996 4.996 0 0023 9.999c2.75 0 5-2.25 5-5C28 2.252 25.75 0 23 0zm0 2c1.669 0 3 1.332 3 3 0 1.67-1.331 3-3 3-1.668 0-3-1.33-3-3 0-1.668 1.332-3 3-3zM5 11c1.668 0 3 1.331 3 3 0 1.668-1.332 3-3 3-1.669 0-3-1.332-3-3 0-1.669 1.331-3 3-3zm18 9c1.669 0 3 1.331 3 3 0 1.668-1.331 3-3 3-1.668 0-3-1.332-3-3 0-1.669 1.332-3 3-3z" fill="currentColor"/></svg>
                </template>
              </BaseIconButton>
            </b-tooltip>
          </template>
          <b-dropdown-item
            v-clipboard:copy="shortUrl"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
          >
            <b-icon pack="far" size="is-medium" icon="clipboard" />
            Copier le lien
          </b-dropdown-item>
          <b-dropdown-item @click="openSuperTrackingForm">
            <b-icon size="is-medium" icon="bolt" />
            Générer le Super Tracking (UTM)
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <ShareNetwork
              network="email"
              :url="shortUrl"
              :title="link.title"
              :description="link.description"
            >
              <b-icon pack="far" size="is-medium" icon="envelope" />
              Partager par email
            </ShareNetwork>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <ShareNetwork
              network="facebook"
              :url="shortUrl"
              :title="link.title"
              :description="link.description"
              :quote="link.title"
            >
              <b-icon pack="fab" size="is-medium" icon="facebook" />
              Partager sur Facebook
            </ShareNetwork>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <ShareNetwork
              network="linkedin"
              :url="shortUrl"
              :title="link.title"
            >
              <b-icon pack="fab" size="is-medium" icon="linkedin" />
              Partager sur LinkedIn
            </ShareNetwork>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <ShareNetwork
              network="pinterest"
              :url="shortUrl"
              :title="link.title"
            >
              <b-icon pack="fab" size="is-medium" icon="pinterest" />
              Partager sur Pinterest
            </ShareNetwork>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <ShareNetwork
              network="whatsapp"
              :url="shortUrl"
              :title="link.title"
              :description="link.description"
            >
              <b-icon pack="fab" size="is-medium" icon="whatsapp" />
              Partager sur WhatsApp
            </ShareNetwork>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <ShareNetwork
              network="telegram"
              :url="shortUrl"
              :title="link.title"
              :description="link.description"
            >
              <b-icon pack="fab" size="is-medium" icon="telegram" />
              Partager sur Telegram
            </ShareNetwork>
          </b-dropdown-item>
        </b-dropdown>
        <b-tooltip v-if="!isOnStatsPage" class="ml-3">
          <template #content>
            Statistiques
          </template>
          <BaseIconButton
            tag="router-link"
            :to="{name: 'link-clicks-stats', params: {uuid: link.uuid}}"
          >
            <template #icon>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.023 26.047h-.325V7.488a2.283 2.283 0 00-2.28-2.279H19.21a2.235 2.235 0 00-.325.033V2.279A2.282 2.282 0 0016.604 0h-5.209a2.281 2.281 0 00-2.279 2.279v8.172a2.28 2.28 0 00-.325-.032H3.58a2.282 2.282 0 00-2.279 2.279v13.349H.977a.977.977 0 100 1.953h26.046a.977.977 0 100-1.953zM3.256 12.698c0-.18.146-.326.325-.326h5.21a.326.326 0 01.325.326v13.349h-5.86V12.698zm7.814 0V2.28c0-.18.146-.325.325-.325h5.21a.326.326 0 01.325.325v23.768h-5.86V12.698zm7.814 13.35V7.487c0-.179.146-.325.325-.325h5.21a.326.326 0 01.326.326v18.558h-5.861z" fill="currentColor"/></svg>
            </template>
          </BaseIconButton>
        </b-tooltip>
        <b-tooltip class="ml-3">
          <template #content>
            Modifier le lien
          </template>
          <BaseIconButton
            tag="router-link"
            :to="{name: 'links-edit', params: { uuid: link.uuid }}"
          >
            <template #icon>
              <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.044 21.309c-.149.749.085 1.523.623 2.063.538.54 1.31.774 2.056.623l4.21-.845a3.553 3.553 0 001.826-.981L22.907 7.974a3.601 3.601 0 000-5.075L21.07 1.05a3.576 3.576 0 00-5.056 0L1.864 15.25a3.573 3.573 0 00-.978 1.827l-.842 4.232zm2.754-3.843c.062-.316.217-.606.445-.833l14.15-14.2a1.623 1.623 0 012.298 0l1.838 1.844a1.637 1.637 0 010 2.307l-14.147 14.2a1.606 1.606 0 01-.83.447l-4.21.845a.326.326 0 01-.382-.384l.838-4.226zM24 27.022a.974.974 0 00-.975-.979H3.53a.974.974 0 00-.844.49.982.982 0 000 .978c.174.303.496.489.844.489h19.496a.974.974 0 00.975-.978z" fill="currentColor"/></svg>
            </template>
          </BaseIconButton>
        </b-tooltip>
        <b-tooltip class="ml-3">
          <template #content>
            <template v-if="qrCode">
              Voir le QR Code
            </template>
            <template v-else>
              Générer un QR Code
            </template>
          </template>
          <b-button
            icon-left="qrcode"
            @click="onSelectQRCode"
          />
        </b-tooltip>
        <b-tooltip class="ml-3" multilined position="is-left">
          <template #content>
            Aperçu du lien sur les réseaux sociaux et messageries
            comme WhatsApp, LinkedIn...
          </template>
          <BaseIconButton
            @click="hasPreviewOpen = true"
          >
            <template #icon>
              <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.289 4.72a.836.836 0 00-.556-.535 7.104 7.104 0 00-2-.278 7.5 7.5 0 107.5 7.5 7.117 7.117 0 00-.277-2 .833.833 0 00-1.296-.436 2.495 2.495 0 01-3.491-3.491.83.83 0 00.12-.76z" fill="currentColor"/><path d="M14.024 0A20.747 20.747 0 00.311 5.496a.976.976 0 101.38 1.38 19.012 19.012 0 0112.333-4.923 19.109 19.109 0 0112.334 4.925.976.976 0 001.38-1.382A20.747 20.747 0 0014.023 0zM14.024 24A20.747 20.747 0 01.311 18.504a.976.976 0 111.38-1.38 19.012 19.012 0 0012.333 4.923 19.109 19.109 0 0012.334-4.925.976.976 0 011.38 1.382A20.747 20.747 0 0114.023 24z" fill="currentColor"/></svg>
            </template>
          </BaseIconButton>
        </b-tooltip>
        <b-dropdown class="ml-3" position="is-bottom-left">
          <template #trigger>
            <b-button icon-left="ellipsis-h" />
          </template>
          <template v-if="link.tags.length">
            <b-dropdown-item custom>
              <span class="has-text-weight-bold">
                Étiquettes ({{ link.tags.length }})
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="tag in link.tags"
              :key="tag.uuid"
              @click="$routeAppend({
                name: 'home',
                query: {page: 1, tag: tag.uuid, tagName: tag.name
              }})">
              {{ tag.name }}
            </b-dropdown-item>
            <b-dropdown-item separator />
          </template>
          <b-dropdown-item @click="changeCollection">
            <b-icon
              class="ml-1"
              pack="far"
              icon="folder-open"
            />
            Changer de dossier
          </b-dropdown-item>
          <b-dropdown-item @click="duplicateLink">
            <b-icon
              class="ml-1"
              pack="far"
              icon="clone"
            />
            Dupliquer le lien
          </b-dropdown-item>
          <b-dropdown-item class="has-text-danger" @click="deleteLink">
            <b-icon
              class="ml-1"
              pack="far"
              icon="trash-alt"
            />
            Supprimer le lien
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- update collection modal -->
    <b-modal
      :active.sync="hasDuplicationOpen"
      :can-cancel="false"
      has-modal-card>
      <DuplicateLinkForm
        :link="link"
        @done="onDuplicateLink"
        @cancel="hasDuplicationOpen = false"
      />
    </b-modal>

    <!-- update collection modal -->
    <b-modal
      :active.sync="hasUpdateCollectionOpen"
      :can-cancel="false"
      has-modal-card>
      <LinkUpdateCollectionForm
        :link="link"
        @done="onUpdateLink"
        @cancel="hasUpdateCollectionOpen = false"
      />
    </b-modal>

    <!-- qrcode modal -->
    <b-modal width="460px" :active.sync="hasQRCodeOpen">
      <figure class="box w-full">
        <img
          v-if="qrCode"
          class="ofit-cover w-full h-full"
          :src="qrCode"
          :alt="link.title"
        >
      </figure>
      <b-button
        icon-left="cloud-download-alt"
        type="is-white"
        expanded
        @click="downloadFile(`${link.label}.svg`, qrCode, true)"
      >
        Télécharger
      </b-button>
    </b-modal>

    <!-- preview modal -->
    <b-modal width="658px" :active.sync="hasPreviewOpen">
      <div class="box p-2">
        <div class="bradius-normal is-clipped">
          <figure class="w-full h-360">
            <img v-if="image" class="ofit-cover w-full h-full" :src="image" :alt="link.title">
            <p v-else class="h-full flex-center-center is-size-4">
              Il n'y a pas d'image
            </p>
          </figure>
          <div class="p-3 has-background-grey-lighter">
            <p class="is-size-7 is-uppercase">
              {{ targetDomain }}
            </p>
            <h2 class="title is-6 mb-0">
              {{ link.title }}
            </h2>
            <p class="is-size-7 has-text-clipped">
              {{ link.description }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </article>
</template>

<style lang="scss" module>
.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 480px;
}

.image {
  flex: none;
  width: 134px;
  height: 120px;
  border: 1px solid $grey-lighter;
}

.texts {
  max-width: 320px;
}

.tags {
 justify-content: flex-end;
}

@include until-widescreen {
  .content {
    width: 460px;
  }
}

@include touch {
  .card {
    flex-wrap: wrap;
  }

  .actions {
    display: flex;
    width: 100%;
    margin-top: pxToRem(20px);
  }

  .tags,
  .buttons {
    width: 50%;
  }

  .tags {
    justify-content: flex-start;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .buttons {
    text-align: right;
  }
}

@include mobile {
  .content,
  .texts,
  .clicks,
  .tags,
  .buttons {
    width: 100%;
  }

  .texts {
    max-width: none;
  }

  .actions {
    display: block;
  }

  .tags {
    justify-content: center;
    &:not(:last-child) {
      margin-bottom: pxToRem(12px);
    }
  }

  .buttons {
    text-align: center;
  }

  .clicks {
    width: 100%;
    margin: pxToRem(20px) 0;
    text-align: center;
  }
}
</style>
