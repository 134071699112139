const globalEnv = {
  supportEmail: 'bonjour@lienvisuel.fr',
  wpAPIURL: 'https://www.lienvisuel.fr/wp-json/wp/v2',
};

const env = {
  development: {
    apiURL: 'http://api.lienvisuel.localhost/api',
    assetURL: 'http://api.lienvisuel.localhost',
    billingURL: 'http://facturation.lienvisuel.localhost/abonnement',
    appURL: 'http://app.lienvisuel.localhost:4444',
    marketingURL: 'https://www.lienvisuel.fr',
    rootDomain: 'lienvisuel.localhost',
    shortDomain: 'lienvu.localhost',
    sentry: {
      dns: '',
      tracesSampleRate: 1.0,
    },
    plans: {
      GROWTH: {
        monthly: 'price_1KZwNPBr9lDgtnJqt6VP1ZAP',
        yearly: 'price_1KZwNPBr9lDgtnJqsiPhkujv',
      },
    },
    keys: {
      logspot: 'pk_xpG4TOqYXfl3QBsMg5i7bdalFHHHmJMXXu0QBRGN',
    },
  },
  staging: {
    apiURL: 'https://api.staging.lienvisuel.fr/api',
    assetURL: 'https://api.staging.lienvisuel.fr',
    billingURL: 'https://facturation.staging.lienvisuel.fr/abonnement',
    appURL: 'https://app.staging.lienvisuel.fr',
    marketingURL: 'https://www.lienvisuel.fr',
    rootDomain: 'staging.lienvisuel.fr',
    shortDomain: 'staging.lienvu.fr',
    sentry: {
      dns: 'https://b2bd245fc0d347308372ae1e9fdbad14@o1279510.ingest.sentry.io/6502741',
      tracesSampleRate: 1.0,
    },
    plans: {
      GROWTH: {
        monthly: 'price_1KZwNPBr9lDgtnJqt6VP1ZAP',
        yearly: 'price_1KZwNPBr9lDgtnJqsiPhkujv',
      },
    },
    keys: {
      logspot: 'pk_xpG4TOqYXfl3QBsMg5i7bdalFHHHmJMXXu0QBRGN',
    },
  },
  production: {
    apiURL: 'https://api.lienvisuel.fr/api',
    assetURL: 'https://api.lienvisuel.fr',
    billingURL: 'https://facturation.lienvisuel.fr/abonnement',
    appURL: 'https://app.lienvisuel.fr',
    marketingURL: 'https://www.lienvisuel.fr',
    rootDomain: 'lienvisuel.fr',
    shortDomain: 'lienvu.fr',
    sentry: {
      dns: 'https://470d2ac7ba2e4a3ea7012e9f6f31e5c9@o1279510.ingest.sentry.io/6502742',
      tracesSampleRate: 1.0,
    },
    plans: {
      GROWTH: {
        monthly: 'price_1LAD35Br9lDgtnJqC3vOwfBu',
        yearly: 'price_1LAD35Br9lDgtnJqSm8zdHgH',
      },
    },
    keys: {
      logspot: 'pk_zIV2Pyg5MzQpvxHiOPuaIxMLn74nE7sZSB-agVl2',
    },
  },
};

export default { ...globalEnv, ...env[process.env.NODE_ENV] };
