<script>
import AppLogo from '@/components/AppLogo.vue';
import GlobalNotice from '@/components/GlobalNotice.vue';
import AllowedClicksProgress from '@/components/AllowedClicksProgress.vue';
import PageFormModal from '@/components/PageFormModal.vue';

export default {
  components: {
    AppLogo,
    GlobalNotice,
    AllowedClicksProgress,
  },
  data: () => ({ hasMenuOpen: false }),
  computed: {
    authUser() {
      return this.$store.state.auth.user;
    },
    isCollectionRoute() {
      return ['collections', 'collection-item']
        .includes(this.$route.name);
    },
    nbUsedClicks() {
      return this.$store.getters['auth/currentTeam'].current_month_click_count;
    },
    nbAllowedClicks() {
      return this.$store.getters['auth/capabilities'].nb_monthly_clicks;
    },
    clickUsageColor() {
      const percent = this.nbUsedClicks / this.nbAllowedClicks;

      return {
        'is-black': percent <= 0.7,
        'is-warning': percent > 0.7 && percent < 0.9,
        'is-danger': percent >= 0.9,
      };
    },
  },
  methods: {
    showHelp() {
      this.$buefy.dialog.alert({
        title: 'Besoin d\'aide ?',
        message: `
          Vous pouvez nous contacter par e-mail à tout moment
          à l'adresse
          <strong>
            <a href="mailto:${this.$env.supportEmail}">
              ${this.$env.supportEmail}</a></strong>,
          nous vous répondrons dès que possible !
        `,
        confirmText: 'Ok !',
      });
    },
    addPage() {
      const modal = this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        component: PageFormModal,
        events: {
          close: () => modal.close(),
        },
      });

      this.$logspot.track({
        event: 'AddPage',
        metadata: { where: 'SideNav' },
      });
    },
  },
};
</script>

<template>
  <div class="has-background-white-ter" :class="$style.layout">
    <div
      :class="[$style.sideMenuOverlay, { [$style.isActive]: hasMenuOpen }]"
      @click="hasMenuOpen = false"
    />
    <nav :class="[$style.sideMenu, { [$style.isActive]: hasMenuOpen }]">
      <div>
        <p class="block" @click="hasMenuOpen = false">
          <AppLogo />
        </p>
        <p class="buttons mt-10" @click="hasMenuOpen = false">
          <BaseIconButton
            class="is-primary-gradient is-justify-content-flex-start"
            tag="router-link"
            :to="{name: 'links-create'}"
            icon-left="plus"
            expanded
            @click="$logspot.track({
              event: 'AddLink',
              metadata: { where: 'SideNav' },
            })"
          >
            Créer un lien
          </BaseIconButton>
          <BaseIconButton
            class="is-justify-content-flex-start"
            type="is-dark"
            icon-pack="fal"
            icon-left="file-alt"
            outlined
            expanded
            @click="addPage"
          >
            Créer une page de liens
          </BaseIconButton>
        </p>
        <ul :class="$style.itemList">
          <li :class="$style.item" @click="hasMenuOpen = false">
            <router-link to="/" :exact-active-class="$style.isActive">
              <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.418 28h11.163a6.615 6.615 0 004.684-1.956 6.7 6.7 0 001.944-4.713v-9.884l1.09.878a1.04 1.04 0 001.034.159 1.057 1.057 0 00.274-1.803L17.398.85a3.802 3.802 0 00-4.794 0l-12.21 9.83a1.055 1.055 0 00.539 1.868c.276.031.553-.05.77-.224l1.089-.878v9.884a6.7 6.7 0 001.944 4.713A6.616 6.616 0 009.419 28zM13.91 2.496a1.727 1.727 0 012.178 0l9.028 7.266v11.569a4.584 4.584 0 01-1.33 3.225 4.527 4.527 0 01-3.205 1.338H9.42a4.528 4.528 0 01-3.205-1.338 4.584 4.584 0 01-1.33-3.225V9.762l9.026-7.266z" fill="currentColor"/><path d="M15 20.672c1.306 0 2.56-.523 3.484-1.452a4.974 4.974 0 001.443-3.506 4.974 4.974 0 00-1.443-3.506A4.912 4.912 0 0015 10.756c-1.307 0-2.56.522-3.484 1.452a4.974 4.974 0 00.002 7.01A4.917 4.917 0 0015 20.672zm0-7.809c.751 0 1.472.3 2.004.836a2.86 2.86 0 01-.001 4.033 2.825 2.825 0 01-4.008-.001 2.862 2.862 0 01.002-4.032A2.828 2.828 0 0115 12.864z" fill="currentColor"/></svg>
              Tableau de bord
            </router-link>
          </li>
          <li :class="$style.item" @click="hasMenuOpen = false">
            <router-link :to="{name: 'domains'}" :active-class="$style.isActive">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.326 9.708A13.992 13.992 0 0014.003 0 13.998 13.998 0 00.68 9.708l-.006.016a13.914 13.914 0 000 8.553l.006.015A13.991 13.991 0 0014.003 28a14 14 0 0013.323-9.708v-.015c.898-2.78.898-5.773 0-8.553v-.016zM14.005 26.051c-1.488 0-3.106-2.865-3.836-7.162h7.672c-.731 4.304-2.349 7.162-3.837 7.162zm-4.09-9.115c-.09-.934-.144-1.91-.144-2.93 0-1.021.055-1.997.145-2.93h8.178c.09.933.144 1.909.144 2.93 0 1.02-.054 1.996-.144 2.93H9.915zm-7.96-2.93c.004-.989.13-1.973.376-2.93h5.624a32.844 32.844 0 00-.138 2.93c0 .976.05 1.953.138 2.93H2.33a12.008 12.008 0 01-.375-2.93zM14.005 1.96c1.487 0 3.105 2.865 3.836 7.162h-7.673c.731-4.304 2.349-7.162 3.837-7.162zm6.049 9.115h5.624c.5 1.922.5 3.94 0 5.86h-5.624c.087-.97.138-1.953.138-2.93 0-.976-.051-1.953-.138-2.93zm4.95-1.953h-5.19a18.357 18.357 0 00-2.162-6.596 12.101 12.101 0 017.357 6.596h-.005zM10.356 2.526a18.358 18.358 0 00-2.162 6.596H2.999a12.098 12.098 0 017.357-6.596zM3 18.89h5.195a18.358 18.358 0 002.162 6.596A12.101 12.101 0 013 18.889zm14.652 6.596a18.36 18.36 0 002.162-6.596h5.195a12.099 12.099 0 01-7.357 6.596z" fill="currentColor"/></svg>
              Domaines
            </router-link>
          </li>
          <li :class="$style.item" @click="hasMenuOpen = false">
            <router-link :to="{name: 'pages'}" :active-class="$style.isActive">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z" fill="currentColor"/></svg>
              Pages
            </router-link>
          </li>
          <li :class="$style.item" @click="hasMenuOpen = false">
            <router-link
              :to="{name: 'collections'}"
              :class="{[$style.isActive]: isCollectionRoute}"
            >
              <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.349 23.998H2.65c-.938 0-1.651-.733-1.651-1.575V3.573c0-.843.711-1.575 1.65-1.575h9.26c.461 0 1.012.32 1.224.68l.893 1.517c.176.301.459.485.614.573.157.088.455.23.796.23h11.916c.936 0 1.647.732 1.647 1.574v15.852c0 .843-.71 1.574-1.651 1.574z" stroke="currentColor" stroke-width="2"/></svg>
              Dossiers
            </router-link>
          </li>
          <li :class="$style.item" @click="hasMenuOpen = false">
            <router-link :to="{name: 'tags'}" :active-class="$style.isActive">
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.737 11.867a3.602 3.602 0 002.548-6.15 3.686 3.686 0 00-5.095 0 3.603 3.603 0 002.547 6.15zM18.58 7.105a1.638 1.638 0 012.317 2.317 1.679 1.679 0 01-2.317 0 1.64 1.64 0 010-2.317z" fill="currentColor"/><path d="M1.438 19.152l7.411 7.411a4.92 4.92 0 006.945 0l10.767-10.766a4.9 4.9 0 001.424-3.853l-.524-6.882A4.887 4.887 0 0022.939.54L16.056.015a4.887 4.887 0 00-3.852 1.425L1.437 12.2a4.92 4.92 0 000 6.951zm1.389-5.56L13.594 2.824a2.946 2.946 0 012.084-.863c.074 0 .152 0 .228.009l6.882.524a2.934 2.934 0 012.714 2.726l.524 6.882a2.947 2.947 0 01-.854 2.312L14.404 25.18a2.952 2.952 0 01-4.17 0l-7.408-7.42a2.952 2.952 0 010-4.169z" fill="currentColor"/></svg>
              Étiquettes
            </router-link>
          </li>
        </ul>
      </div>

      <GlobalNotice
        targetClass="flex-none has-background-light"
        :class="$style.trial"
        type="vertical"
      />
    </nav>
    <main :class="$style.main">
      <nav class="container px-5" :class="$style.userMenu">
        <div :class="$style.sideMenuResponsive">
          <b-button
            :class="$style.sideMenuToggle"
            type="is-primary-gradient"
            @click="hasMenuOpen = true"
          >
            Menu
          </b-button>
          <AppLogo class="ml-5" isResponsive />
        </div>
        <b-dropdown class="has-text-left" position="is-bottom-left">
          <template #trigger>
            <b-button
              icon-left="user-circle"
              icon-right="chevron-down"
            >
              Mon compte
            </b-button>
          </template>
          <b-dropdown-item class="w-280" custom>
            <AllowedClicksProgress />
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item has-link>
            <router-link :to="{name: 'settings'}">
              Paramètres
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <router-link :to="{name: 'billing'}">
              Abonnement
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item has-link>
            <router-link :to="{name: 'team'}">
              Équipe <span class="tag is-black ml-2">Bientôt</span>
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item has-link>
            <router-link :to="{name: 'godfather'}">
              <b-icon
                type="is-primary"
                class="is-size-5 mr-1"
                icon="gift"
              />
              Affiliation
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item @click="showHelp">
            <b-icon
              class="is-size-5 mr-1"
              pack="far"
              icon="life-ring"
            />
            Aide
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item has-link>
            <router-link :to="{name: 'logout'}">
              Déconnexion
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </nav>
      <div class="container p-5">
        <GlobalNotice />
        <slot />
      </div>
    </main>
  </div>
</template>

<style lang="scss" module>
.layout {
  display: flex;
  min-height: 100vh;
}

.userMenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 20px 0;
}

.sideMenuResponsive {
  display: none;
}

.sideMenuToggle {
  min-height: auto;
  height: 32px !important;
}

.sideMenu {
  position: sticky;
  top: 0;
  flex: 0 0 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: 100vh;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 20px 20px 0px;

  &Overlay {
    display: none;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
}

.itemList {
  margin: 34px -30px 0;
}

.item {
  color: $grey-light;

  a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    color: inherit;
    font-size: $size-6;
    font-weight: $weight-semibold;

    &.isActive,
    &:hover {
      color: $black;

      svg {
        color: $primary;
      }
    }

    &.isActive {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        background: $primary;
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
}

.main {
  width: 100%;
}

@include until-widescreen {
  .sideMenuResponsive {
    display: flex;
    align-items: center;
  }

  .userMenu {
    justify-content: space-between;
  }

  .sideMenu {
    display: none;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;

    &.isActive {
      display: flex;
    }

    &Overlay {
      &.isActive {
        display: block;
      }
    }
  }
}
</style>
