<script>
import BaseNotice from './BaseNotice.vue';
import AllowedClicksProgress from './AllowedClicksProgress.vue';

export default {
  components: { BaseNotice, AllowedClicksProgress },

  props: {
    type: {
      type: String,
      default: () => 'horizontal',
      validator(value) {
        return ['vertical', 'horizontal'].includes(value);
      },
    },
  },
};
</script>

<template>
  <BaseNotice v-bind="{ ...$attrs, type }">
    <template #image>
      <img
        src="@/assets/images/3d_dashboard.png"
        alt="image"
      />
    </template>

    <h3 class="is-size-4 has-text-weight-bolder mb-2 mt-3">
      Vous avez presque utilisé tous vos clics !
    </h3>

    <p class="is-size-7">
      Vous avez utilisé plus de <b>90%</b> de vos clics,
      pensez à changer de plan pour ne pas avoir vos liens bloqué.
    </p>

    <div v-if="type === 'horizontal'" class="mt-4 mb-3">
      <AllowedClicksProgress />
    </div>

    <template #action>
      <a
        class="button is-warning-gradient w-full"
        :href="$env.billingURL"
        @click="$logspot.track({
          event: 'GoToSubscription',
          metadata: { where: 'ClickLimitAlmostReachedNotice' },
        })"
      >
        Voir les offres
      </a>
    </template>
  </BaseNotice>
</template>
