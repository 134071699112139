import APIPage from '@/services/API/Page';

export const state = () => ({
  data: null,
  current: null,
});

export const mutations = {
  set(localState, payload) {
    localState.data = payload;
  },
  setCurrent(localState, payload) {
    localState.current = payload;
  },
  add(localState, payload) {
    const data = localState.data || [];
    localState.data = [...data, payload];
  },
  update(localState, payload) {
    const data = localState.data || [];
    localState.data = data.map((v) => (v.uuid === payload.uuid ? payload : v));
  },
  remove(localState, payload) {
    const data = localState.data || [];
    localState.data = data.filter((v) => v.uuid !== payload.uuid);
  },

  addBlock(localState, payload) {
    if (!localState.current) {
      return;
    }

    const data = localState.current.blocks || [];
    localState.current.blocks = [...data, payload];
  },
  updateBlock(localState, payload) {
    if (!localState.current) {
      return;
    }

    const data = localState.current.blocks || [];
    localState.current.blocks = data.map((v) => (
      v.uuid === payload.uuid ? payload : v
    ));
  },
  updateBlocks(localState, payload) {
    if (!localState.current) {
      return;
    }

    localState.current.blocks = payload;
  },
  removeBlock(localState, payload) {
    if (!localState.current) {
      return;
    }

    const data = localState.current.blocks || [];
    localState.current.blocks = data
      .filter((v) => v.uuid !== payload.uuid)
      .sort((a, b) => a.position - b.position);
  },
};

export const actions = {
  create({ commit }, payload) {
    return APIPage.create(payload)
      .then((data) => {
        commit('add', data);
        return data;
      });
  },
  load({ commit }, { uuid }) {
    return APIPage.get(uuid)
      .then((data) => {
        commit('setCurrent', data);
        return data;
      });
  },
  loadAll({ commit }, payload) {
    return APIPage.getAll(payload)
      .then((data) => {
        commit('set', data);
        return data;
      });
  },
  update({ commit }, { uuid, data }) {
    return APIPage.update(uuid, data)
      .then((updatedData) => {
        commit('update', updatedData);
        return data;
      });
  },
  delete({ commit }, payload) {
    return APIPage.delete(payload.uuid)
      .then(() => commit('remove', payload));
  },

  createBlock({ commit }, { pageUUID, data }) {
    return APIPage.createBlock(pageUUID, data)
      .then((resData) => {
        commit('addBlock', resData);
        return resData;
      });
  },
  updateBlock({ commit }, { uuid, data }) {
    return APIPage.updateBlock(uuid, data)
      .then((resData) => {
        commit('updateBlock', resData);
        return resData;
      });
  },
  updateBlocks({ commit }, data) {
    return APIPage.updateBlocks(data)
      .then((resData) => {
        commit('updateBlocks', resData);
        return resData;
      });
  },
  deleteBlock({ commit }, data) {
    return APIPage.deleteBlock(data.uuid)
      .then(() => commit('removeBlock', data));
  },
};

export const getters = {
};
