import Auth from '@/services/Auth';
import APIAuth from '@/services/API/Auth';
import APIUser from '@/services/API/User';
import router from '@/config/router';
import dayjs from '@/config/daysjs';

export const state = () => ({
  user: null,
});

export const mutations = {
  user(localState, payload) {
    localState.user = payload;
  },
  incrementCounter(localState, key) {
    const count = localState.user.current_team[key];

    if (count != null) {
      localState.user.current_team[key] = count + 1;
    }
  },
};

let readyPromise;
let isReady = false;

export const actions = {
  ready({ commit }) {
    if (isReady) {
      return Promise.resolve();
    }

    if (readyPromise) {
      return readyPromise;
    }

    readyPromise = APIUser.getMe()
      .then((user) => {
        isReady = true;
        readyPromise = null;
        commit('user', user);
      })
      .catch(() => Auth.csrf().then(() => null));

    return readyPromise;
  },
  register({ commit }, payload) {
    return APIUser.register(payload)
      .then((user) => {
        commit('user', user);
        router.push(Auth.routes.afterRegister);

        return user;
      });
  },
  registerVdd({ commit }, payload) {
    return APIUser.registerVdd(payload)
      .then((user) => {
        commit('user', user);
        router.push(Auth.routes.afterRegister);

        return user;
      });
  },
  login({ commit }, payload) {
    return Auth.login(payload)
      .then((user) => {
        commit('user', user);
        router.push(Auth.routes.afterLogin);

        return user;
      });
  },
  logout({ commit }) {
    return Auth.logout()
      .then(() => {
        commit('user', null);
        router.push(Auth.routes.afterLogout);
      });
  },
  verifyEmail({ commit }, { id, hash, params }) {
    return APIAuth.verifyEmail(id, hash, params)
      .then((user) => commit('user', user));
  },
  updatePassword({ commit }, payload) {
    return APIUser.updatePassword(payload)
      .then((user) => commit('user', user));
  },
  update({ commit }, payload) {
    return APIUser.update(payload)
      .then((user) => commit('user', user));
  },
};

export const getters = {
  isLoggedIn(localState) {
    return localState.user != null;
  },
  hasEmailVerified(localState, localGetters) {
    return localGetters.isLoggedIn && !!localState.user.email_verified_at;
  },
  currentTeam(localState) {
    return localState.user && localState.user.current_team;
  },
  currentMonthLimitClickErrorCount(localState, localGetters) {
    return localGetters.currentTeam.current_month_limit_click_error_count;
  },
  capabilities(localState, localGetters) {
    return localGetters.currentTeam && localGetters.currentTeam.capabilities;
  },
  canAddDynamicLink(localState, localGetters) {
    const count = localGetters.currentTeam?.link_dynamic_count;
    const limit = localGetters.currentTeam?.capabilities.nb_dynamic_links;

    console.log(count, limit);

    return (limit === -1 || limit > count);
  },
  nbUsedClicks(localState, localGetters) {
    return localGetters.currentTeam.current_month_click_count;
  },
  nbAllowedClicks(localState, localGetters) {
    return localGetters.capabilities.nb_monthly_clicks;
  },
  clickUsagePercent(localState, localGetters) {
    return localGetters.nbUsedClicks / localGetters.nbAllowedClicks;
  },
  hasAlmostReachedClickLimit(localState, localGetters) {
    return localGetters.clickUsagePercent >= 0.9;
  },
  hasReachedClickLimit(localState, localGetters) {
    return localGetters.clickUsagePercent >= 1;
  },
  isOnTrial(localState, localGetters) {
    const team = localGetters.currentTeam;

    return (
      !team.has_active_subscription
      && !team.is_special_account
      && dayjs().isBefore(dayjs.utc(team.trial_ends_at).local())
    );
  },
  isAccountLocked(localState, localGetters) {
    const team = localGetters.currentTeam;

    if (team) {
      return (
        !team.has_active_subscription
        && !team.is_special_account
        && dayjs().isAfter(dayjs.utc(team.trial_ends_at).local())
      );
    }

    return false;
  },
  subscription(localState, localGetters) {
    return (
      localGetters.currentTeam
      && localGetters.currentTeam.subscriptions[0]
    );
  },
};
