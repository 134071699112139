import DOMPurify from 'dompurify';

export default {
  filters: {
    toShortNb(value) {
      if (value < 1000) {
        return value;
      }

      if (value < 1000000) {
        return `${Math.round(value / 1000)}K`;
      }

      if (value < 1000000000) {
        return `${Math.round(value / 1000000)}M`;
      }

      if (value < 1000000000000) {
        return `${Math.round(value / 1000000000)}Md`;
      }

      return `> ${Math.round(value / 1000000000)}Md`;
    },
    toPercent([nb1, nb2, decimal = 2]) {
      if (!nb2) {
        return '0%';
      }

      const percent = (nb1 / nb2) * 100;
      const divider = 10 ** decimal;

      return `${Math.round(percent * divider) / divider}%`;
    },
    safeHTML(html) {
      return DOMPurify.sanitize(html);
    },
    stripHTML(html) {
      const element = document.createElement('div');
      element.innerHTML = DOMPurify.sanitize(html);
      return element.textContent || element.innerText || '';
    },
  },
};
