<script>
import { addOrUpdateForm, apiForm } from '@/mixins/form';

export default {
  mixins: [
    apiForm({ formData: 'pageData', formProcess: 'save' }),
    addOrUpdateForm({ formData: 'pageData', formEntity: 'page' }),
  ],
  props: {
    page: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isFetching: true,
    pageData: {
      domain: null,
      slug: '',
      name: '',
      styles: {},
      settings: {},
    },
  }),
  computed: {
    authUser() {
      return this.$store.state.auth.user;
    },
    domains() {
      return this.$store.state.domain.data;
    },
    urlPreview() {
      if (!this.pageData.domain) {
        return '';
      }

      return `https://${this.pageData.domain}/@${this.pageData.slug}`;
    },
  },
  methods: {
    setDefaultDomain() {
      // if (this.pageData.domain === null) {
      //   let firstActiveDomain;

      //   if (this.domains && this.domains.length > 0) {
      //     firstActiveDomain = this.domains
      //       .find((v) => v.is_enabled && v.is_verified);
      //   }

      //   this.pageData.domain = (
      //     firstActiveDomain?.name || this.$env.shortDomain
      //   );
      // }

      if (!this.page) {
        this.pageData.domain = this.$env.shortDomain;
      }
    },
    save(data) {
      if (this.page) {
        const { uuid } = this.page;
        return this.$store.dispatch('page/update', { uuid, data })
          .then(() => this.$emit('close'));
      }

      return this.$store.dispatch('page/create', data)
        .then((resData) => {
          this.$router.push({
            name: 'page-blocks',
            params: { uuid: resData.uuid },
          });
          this.$emit('close');
        });
    },
  },
  created() {
    this.isFetching = true;
    this.$store.dispatch('domain/load')
      .then(this.setDefaultDomain)
      .finally(() => (this.isFetching = false));
  },
};
</script>

<template>
  <BaseModalCard tag="form" @submit.prevent="formSubmit">
    <template #title>
      <template v-if="page">
        Modifier la page
      </template>
      <template v-else>
        Ajouter une page
      </template>
    </template>
    <template #body>
      <b-field>
        <div class="control w-50p">
          <b-field label="Domaine" label-for="domain">
            <b-select
              v-model="pageData.domain"
              id="domain"
              icon="globe"
              :disabled="!!page"
              required
              expanded
            >
              <template v-if="domains && domains.length">
                <option
                  v-for="domain in domains"
                  :key="domain.uuid"
                  :class="{'has-background-grey-lighter': !domain.is_enabled}"
                  :value="domain.name"
                  :disabled="!domain.is_enabled || !domain.is_verified">
                  {{ domain.name }}
                  <span v-if="!domain.is_verified">
                    (en attente de validation)
                  </span>
                  <span v-else-if="!domain.is_enabled">
                    (désactivé)
                  </span>
                </option>
              </template>
              <option :value="$env.shortDomain">
                {{ $env.shortDomain }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="control w-50p">
          <b-field label="URL" label-for="slug" :addons="false">
            <b-input
              v-model.trim="pageData.slug"
              id="slug"
              maxlength="100"
              minlength="3"
              placeholder="MonSuperNom"
              pattern="^[a-zA-Z0-9-_]+$"
              :has-counter="false"
              :disabled="!!page"
              expanded
              required
            />
          </b-field>
        </div>
      </b-field>
      <p class="field help has-text-right is-size-7 mt-3">
        Lettres et/ou chiffres, sans accents, ni espaces
      </p>
      <b-field v-if="!page" label="Nom" label-for="name" :addons="false">
        <b-input
          v-model.trim="pageData.name"
          id="name"
          maxlength="100"
          minlength="3"
          placeholder="Mon Super Nom"
          :has-counter="false"
          expanded
          required
        />
      </b-field>
      <p class="notification py-1 px-3 is-size-7">
        <strong class="is-size-8">Aperçu</strong>
        <br>
        {{ urlPreview }}
      </p>
    </template>
    <template #foot>
      <div class="buttons">
        <b-button type="is-text" @click="$emit('close')">
          Annuler
        </b-button>

        <b-button
          type="is-primary-gradient"
          native-type="submit"
          :loading="formIsLoading">
          <template v-if="page">
            Modifier
          </template>
          <template v-else>
            Ajouter
          </template>
        </b-button>
      </div>
    </template>
  </BaseModalCard>
</template>
