import MFLayout from './MFLayout.vue';

export default {
  install(Vue, options = { defaultLayout: 'Default', layouts: {} }) {
    Vue.component('MFLayout', MFLayout);
    Vue.prototype.$layout = {
      default: options.defaultLayout,
      current: options.defaultLayout,
      layouts: options.layouts,
    };
  },
};
