import Vue from 'vue';
import Buefy from 'buefy';

Vue.use(Buefy, {
  defaultFirstDayOfWeek: 1,
  defaultIconPack: 'fas',
  defaultTooltipType: 'is-dark',
  defaultNoticeQueue: false,
  defaultToastDuration: 5000,
  defaultSnackbarDuration: 7500,
  defaultNotificationDuration: 7500,
  defaultNotificationPosition: 'is-top',
  defaultDialogConfirmText: 'Confirmer',
  defaultDialogCancelText: 'Annuler',
});
