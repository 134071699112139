<script>
export default {
  props: {
    tag: {
      type: [String, Object],
      default: 'div',
    },
    titleTag: {
      type: [String, Object],
      default: 'h2',
    },
    columns: {
      type: Array,
      default: () => (['is-5', 'is-7']),
    },
  },
};
</script>

<template functional>
  <component
    :is="props.tag"
    class="columns is-multiline"
    :class="[data.staticClass, data.class]"
    :style="[data.staticStyle, data.style]"
    v-bind="data.attrs"
    v-on="data.on"
  >
    <div class="column content pr-10 pr-3-mobile" :class="props.columns[0]">
      <component v-if="slots().title" :is="props.titleTag" class="title is-4">
        <slot name="title" />
      </component>
      <slot name="content" />
    </div>
    <div v-if="slots().form" class="column" :class="props.columns[1]">
      <slot name="form" />
    </div>
    <slot v-else />
  </component>
</template>
