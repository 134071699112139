import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from './auth';
import * as collection from './collection';
import * as domain from './domain';
import * as tag from './tag';
import * as link from './link';
import * as page from './page';
import * as affiliation from './affiliation';

Vue.use(Vuex);

const modules = {
  auth,
  collection,
  domain,
  tag,
  link,
  page,
  affiliation,
};

Object.keys(modules)
  .forEach((key) => (modules[key].namespaced = true));

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
});
