import axios from '@/config/axios';

export default {
  csrf() {
    return axios.$get('/csrf');
  },
  login(data) {
    return axios.$post('/login', data);
  },
  logout() {
    return axios.$post('/logout');
  },
  verifyEmail(id, hash, params) {
    return axios.$get(`/email/verif/${id}/${hash}`, { params });
  },
  resendEmailVerification() {
    return axios.$post('/email/verification');
  },
  askPassword(email) {
    return axios.$post('/password/ask', { email });
  },
  resetPassword({ token, email, password }) {
    return axios.$post('/password/reset', { token, email, password });
  },
};
