<script>
import { apiForm, addOrUpdateForm } from '@/mixins/form';

export default {
  mixins: [
    apiForm({ formData: 'linkData', formProcess: 'save' }),
    addOrUpdateForm({ formData: 'linkData', formEntity: 'link' }),
  ],
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isFetching: false,
    linkData: {
      collection: null,
    },
  }),
  computed: {
    collections() {
      return this.$store.state.collection.data;
    },
  },
  methods: {
    save(data) {
      const { uuid } = this.link;
      return this.$store.dispatch('link/update', { uuid, data })
        .then((link) => {
          this.$emit('done', link);
          this.$buefy.toast.open({
            type: 'is-success',
            message: 'Le lien a été mis à jour.',
          });
        });
    },
  },
  created() {
    this.isFetching = true;
    this.$store.dispatch('collection/load')
      .finally(() => (this.isFetching = false));

    this.linkData.collection = this.link.collection
      ? this.link.collection.uuid
      : null;
  },
};
</script>

<template>
  <BaseModalCard tag="form" @submit.prevent="formSubmit">
    <template #title>
      Déplacer le lien dans un dossier
    </template>
    <template #body>
      <p
        v-if="collections.data && collections.data.length <= 0"
        class="notification has-text-weight-bold">
        Vous n'avez pas de dossiers.
      </p>
      <b-field
        v-else-if="collections.data && collections.data.length > 0"
        label="Choisissez un dossier"
        label-for="collection"
      >
        <b-select v-model="linkData.collection" id="collection" expanded>
          <option :value="null">Aucun</option>
          <option
            v-for="collection in (collections.data && collections.data)"
            :key="collection.uuid"
            :value="collection.uuid">
            {{ collection.name }}
          </option>
        </b-select>
      </b-field>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Annuler
      </b-button>
      <b-button
        type="is-primary-gradient"
        native-type="submit"
        :loading="formIsLoading"
        :disabled="collections.data && collections.data.length <= 0"
      >
        Déplacer
      </b-button>
    </template>
  </BaseModalCard>
</template>
