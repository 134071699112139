import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

dayjs.locale('fr');

Vue.filter('fromUTC', (value, format) => dayjs.utc(value, format));
Vue.filter('fromNow', (value) => dayjs(value).local().fromNow());
Vue.filter('formatDate', (value, outputFormat) => dayjs(value).local().format(outputFormat));

Vue.prototype.$dayjs = dayjs;

export default dayjs;
