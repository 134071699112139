import router from '@/config/router';
import store from '@/config/store';

router.beforeEach((to, from, next) => {
  console.log('auth middleware : before ready');

  store.dispatch('auth/ready')
    .then(() => {
      const { auth, verified, lockedCanSee } = to.meta;
      const isLoggedIn = store.getters['auth/isLoggedIn'];
      const isAccountLocked = store.getters['auth/isAccountLocked'];
      const hasEmailVerified = store.getters['auth/hasEmailVerified'];

      console.log('auth middleware : after ready');
      console.log(auth, isLoggedIn);

      if (auth === true && !isLoggedIn) {
        next({ name: 'login' });
        return;
      }

      if (auth === false && isLoggedIn) {
        next({ name: 'home' });
        return;
      }

      if (verified !== false && !hasEmailVerified && isLoggedIn) {
        next({ name: 'verify-email-notice' });
        return;
      }

      if (hasEmailVerified && to.name === 'verify-email-notice') {
        next({ name: 'home' });
        return;
      }

      if (lockedCanSee !== true && isAccountLocked && to.name !== 'account-locked') {
        next({ name: 'account-locked' });
        return;
      }

      if (!isAccountLocked && to.name === 'account-locked') {
        next({ name: 'home' });
        return;
      }

      next();
    });
});
