import qs from 'qs';
import axios from '@/config/axios';

export default {
  create(data) {
    return axios.$post('/collections', data);
  },
  getAll(params) {
    return axios.$get('/collections', {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { encode: false, arrayFormat: 'brackets' });
      },
    });
  },
  get(uuid) {
    return axios.$get(`/collections/${uuid}`);
  },
  update(uuid, data) {
    return axios.$put(`/collections/${uuid}`, data);
  },
  delete(uuid) {
    return axios.$delete(`/collections/${uuid}`);
  },
};
