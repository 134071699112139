<script>
import BaseNotice from './BaseNotice.vue';

export default {
  components: { BaseNotice },
};
</script>

<template>
  <BaseNotice v-bind="$attrs">
    <template #image>
      <img
        src="@/assets/images/3d_dashboard.png"
        alt="image"
      />
    </template>

    <h3 class="is-size-4 has-text-weight-bolder mb-2">
      Periode d'essai
    </h3>

    <p class="is-size-7">
      Votre période d'essai se termine le
      <strong>{{
        $store.getters['auth/currentTeam'].trial_ends_at | fromUTC | formatDate('DD/MM/YY [à] HH:mm')
      }}</strong>.
      <br>
      Abonnez-vous pour profiter de 100% des fonctionnalités.
    </p>

    <template #action>
      <a
        class="button is-warning-gradient w-full"
        :href="$env.billingURL"
        @click="$logspot.track({
          event: 'GoToSubscription',
          metadata: {
            where: `Trial${$attrs.type.toUpperCase()}Notice`,
          },
        })"
      >
        Voir les offres
      </a>
    </template>
  </BaseNotice>
</template>
