<script>
export default {
  props: {
    link: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    hasMorefields: false,
    lv: {
      source: '',
      medium: '',
      campaign: '',
      content: '',
      term: '',
    },
  }),
  computed: {
    url() {
      let final = `${window.location.protocol}//${this.link.domain}/${this.link.slug}`;

      const source = this.lv.source.trim();
      const medium = this.lv.medium.trim();
      const campaign = this.lv.campaign.trim();
      const content = this.lv.content.trim();
      const term = this.lv.term.trim();

      if (source || medium || campaign || content || term) {
        final += '?';
      }

      if (source) {
        final += `lv_source=${encodeURIComponent(source)}&`;
      }

      if (medium) {
        final += `lv_medium=${encodeURIComponent(medium)}&`;
      }

      if (campaign) {
        final += `lv_campaign=${encodeURIComponent(campaign)}&`;
      }

      if (content) {
        final += `lv_content=${encodeURIComponent(content)}&`;
      }

      if (term) {
        final += `lv_term=${encodeURIComponent(term)}&`;
      }

      if (final[final.length - 1] === '&') {
        final = final.slice(0, -1);
      }

      return final;
    },
  },
  methods: {
    onCopySuccess() {
      this.$messages.success('Le lien a été copié');
    },
    onCopyError() {
      this.$messages.error('Une erreur est survenue lors de la copie du lien');
    },
  },
};
</script>

<template>
  <BaseModalCard>
    <template #title>
      Générer un lien avec Super Tracking
    </template>
    <template #body>
      <form class="columns is-multiline" @submit.prevent>
        <div class="column is-6">
          <b-field label="Source">
            <b-input v-model="lv.source" />
            <template #message>
              Vous permet de tracker où vous postez le lien
              : blog, facebook, twitter, newsletter, sms, etc.
            </template>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Medium">
            <b-input v-model="lv.medium" />
            <template #message>
              Vous permet de préciser le contenu ou la localisation
              de la source, comme un titre, le nom d'un groupe, etc.
            </template>
          </b-field>
        </div>
        <div class="column is-6">
          <b-field label="Campagne">
            <b-input v-model="lv.campaign" />
            <template #message>
              Vous permet de préciser la catégorie globale
              de votre campagne de diffusion lors de plusieurs lancements.
            </template>
          </b-field>
        </div>
        <div class="column is-12">
          <a
            v-if="!hasMorefields"
            class="has-text-black is-size-7 is-underlined"
            @click.prevent="hasMorefields = true">
            Voir les champs avancés
          </a>
          <hr v-else class="my-0">
        </div>
        <div v-if="hasMorefields" class="column is-6">
          <b-field label="Contenu">
            <b-input v-model="lv.content" />
            <template #message>
              Si vous avez besoin d'encore plus de segmentation.
            </template>
          </b-field>
        </div>
        <div v-if="hasMorefields" class="column is-6">
          <b-field label="Terme">
            <b-input v-model="lv.term" />
            <template #message>
              Si vous avez besoin d'encore plus de segmentation.
            </template>
          </b-field>
        </div>
      </form>
    </template>
    <template #foot>
      <p class="w-full has-text-centered px-3 break-words">
        <a
          class="has-text-black has-text-weight-bold"
          :href="url"
          v-clipboard:copy="url"
          v-clipboard:success="onCopySuccess"
          v-clipboard:error="onCopyError"
          @click.prevent
        >
          <b-icon type="is-grey" pack="far" icon="clipboard" />
          {{ url }}
        </a>
      </p>
    </template>
  </BaseModalCard>
</template>
