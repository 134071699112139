import axios from '@/config/axios';

export default {
  create(data) {
    return axios.$post('/tags', data);
  },
  getAll(params) {
    return axios.$get('/tags', { params });
  },
  get(uuid) {
    return axios.$get(`/tags/${uuid}`);
  },
  update(uuid, data) {
    return axios.$put(`/tags/${uuid}`, data);
  },
  delete(uuid) {
    return axios.$delete(`/tags/${uuid}`);
  },
};
