import router from '@/config/router';
import store from '@/config/store';

router.beforeEach((to, from, next) => {
  if (from.query.gf) {
    store.commit('affiliation/set', from.query.gf);
  }

  if (to.query.gf) {
    store.commit('affiliation/set', to.query.gf);
  }

  next();
});
