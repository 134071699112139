import Vue from 'vue';
import { ToastProgrammatic } from 'buefy';

Vue.prototype.$messages = {
  copySuccess() {
    ToastProgrammatic.open({
      type: 'is-success',
      message: 'Le texte est copié !',
    });
  },
  success(message = 'Votre demande a été enregistrée') {
    ToastProgrammatic.open({
      type: 'is-success',
      message,
    });
  },
  error(message = 'Une erreur est survenue') {
    ToastProgrammatic.open({
      type: 'is-danger',
      message,
    });
  },
};
