import axios from '@/config/axios';

export default {
  register(data) {
    return axios.$post('/register', data);
  },
  registerVdd(data) {
    return axios.$post('/register-vdd', data);
  },
  getMe() {
    return axios.$get('/users/me');
  },
  update(data) {
    return axios.$put('/users/me', data);
  },
  updatePassword(data) {
    return axios.$put('/users/me/password', data);
  },
};
