export default null;

export const blobToBase64 = (blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.onerror = () => reject;
  reader.onabort = () => reject;
  reader.readAsDataURL(blob);
});

export const urlToFile = (url) => new Promise((resolve, reject) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();

  img.src = url;
  img.crossOrigin = 'anonymous';
  img.onerror = reject;
  img.onabort = reject;
  img.onload = () => {
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;
    ctx.drawImage(img, 0, 0);
    try {
      canvas.toBlob((blob) => resolve(blob));
    } catch {
      console.error('error de toBlog');
    }
  };
});

export const pluralText = (text, nb, suffix = 's') => (nb > 1 ? text + suffix : text);

export const randomAlphaNum = (length, hasDash = true) => {
  let result = '';
  const chars = `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789${
    hasDash ? '-_' : ''}`;

  for (let i = 0; i < length; i += 1) {
    result += chars[(Math.floor(Math.random() * chars.length))];
  }

  return result;
};

export const promiseTimeout = (promise, duration = 0) => {
  const timeout = new Promise((resolve) => {
    if (!duration) {
      resolve();
      return;
    }

    setTimeout(resolve, duration);
  });

  return Promise.all([promise, timeout]).then(([res]) => res);
};

export const downloadFile = async (filename, url, needsFetch = false) => {
  let fileURL = url;

  if (needsFetch) {
    try {
      const file = await fetch(fileURL);
      const blob = await file.blob();
      fileURL = URL.createObjectURL(blob);
    } catch {
      // do something
    }
  }

  const el = document.createElement('a');
  el.setAttribute('href', fileURL);
  el.setAttribute('download', filename);
  document.body.appendChild(el);
  el.click();
  el.remove();
};

export const formatNumber = (number) => {
  number = Number(number);

  if (number > (1e9 - 1)) {
    return `${Math.round((number / 1e9) * 10) / 10}Md`;
  }

  if (number > (1e6 - 1)) {
    return `${Math.round((number / 1e6) * 10) / 10}M`;
  }

  if (number > (1e3 - 1)) {
    return `${Math.round((number / 1e3) * 10) / 10}K`;
  }

  return number.toString();
};

export function getYouTubeEmbedUrl(url) {
  const regex = /(?:youtube(?:-nocookie)?\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
  const match = url.match(regex);
  const videoId = match ? match[1] : null;

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }

  return null;
}

export function getVimeoEmbedUrl(url) {
  const regex = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const match = url.match(regex);
  const videoId = match ? match[3] : null;

  if (videoId) {
    return `https://player.vimeo.com/video/${videoId}`;
  }

  return null;
}
