import axios from '@/config/axios';

export default {
  create(data) {
    return axios.$post('/pages', data);
  },
  getAll(params) {
    return axios.$get('/pages', { params });
  },
  get(uuid) {
    return axios.$get(`/pages/${uuid}`);
  },
  update(uuid, data) {
    return axios.$put(`/pages/${uuid}`, data);
  },
  delete(uuid) {
    return axios.$delete(`/pages/${uuid}`);
  },

  createBlock(pageUUID, data) {
    return axios.$post(`/pages/${pageUUID}/blocks`, data);
  },
  getBlocks(pageUUID, params) {
    return axios.$get(`/pages/${pageUUID}/blocks`, { params });
  },
  getBlock(uuid) {
    return axios.$get(`/blocks/${uuid}`);
  },
  updateBlock(uuid, data) {
    return axios.$put(`/blocks/${uuid}`, data);
  },
  updateBlocks(data) {
    return axios.$put('/blocks', data);
  },
  deleteBlock(uuid) {
    return axios.$delete(`/blocks/${uuid}`);
  },
};
