import Vue from 'vue';
import Logspot from '@logspot/web';
import env from '@/env';

Logspot.init({
  publicKey: env.keys.logspot,
  // enableAutoPageviews: false,
  cookieDomain: `.${env.rootDomain}`,
});

Vue.prototype.$logspot = Logspot;

export default Logspot;
